// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useState, useRef, useEffect } from "react";
// import {
//   TopNavHeader,
//   charPromptsCtx,
//   defaultCharPrompts,
//   useCharPrompts,
// } from "./components";
import { lightGreen, grey, blue, green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Stack,
  Avatar,
  OutlinedInput,
  List,
  ListItem,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import useWebSocket from "react-use-websocket";
import { Formik, Form, useFormik } from "formik";
import { useAuthToken } from "../commons/use-auth";
import botlogo from "../ai-logo.svg";
import { API_socket } from "../commons/apigw";
import { useLocalStorage } from "../commons/localStorage";

function generateUniqueId() {
  const timestamp = Date.now();
  const randomNumber = Math.random();
  const hexadecimalString = randomNumber.toString(16).slice(3);

  return `id-${timestamp}-${hexadecimalString}`;
}

const BOTNAME = "AI";

function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    //   children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    children: name[0].toUpperCase() + name[name.length - 1].toUpperCase(),
  };
}

const MsgItem = ({ who, text }) => {
  let id = 0;
  const newlines = text.split("\n").map((it) => (
    <span key={id++}>
      {it}
      <br />
    </span>
  ));
  return who !== BOTNAME ? (
    <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
        <TextItem sx={{ bgcolor: lightGreen[400] }}> {newlines}</TextItem>
        <Avatar {...stringAvatar(who)} />
      </Stack>
    </ListItem>
  ) : (
    <ListItem>
      <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
        {/* <Avatar src={botlogo} alt={"AIBot"} /> */}
        <Avatar {...stringAvatar("史强")} />
        <TextItem> {newlines}</TextItem>
      </Stack>
    </ListItem>
  );
};

const TextItem = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1.2,
        // m: 1.2,
        whiteSpace: "normal",
        bgcolor: grey[100],
        color: grey[800],
        border: "1px solid",
        borderColor: grey[300],
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
};

const ChatBox = ({ msgItems, loading }) => {
  const [loadingtext, setLoaderTxt] = useState(".");
  useEffect(() => {
    let textContent = "";
    let interval = setInterval(() => {
      setLoaderTxt((v) => v + ".");
      textContent += ".";
      if (textContent.length > 5) {
        setLoaderTxt(".");
        textContent = "";
      }
    }, 500);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);
  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [msgItems.length]);
  const items = msgItems.map((msg) => (
    <MsgItem key={generateUniqueId()} who={msg.who} text={msg.text} />
  ));

  return (
    <List
      sx={{
        position: "relative",
        overflow: "auto",
      }}
    >
      {/* <MsgItem id={generateUniqueId()} who="AI" text ={"Welcome! Can I help you? 我还会中文以及其他999种语言"}/> */}
      {items}
      {loading ? <MsgItem who={BOTNAME} text={loadingtext} /> : <div />}
      <ListItem ref={scrollRef} />
    </List>
  );
};

const AI_GREETING = "你好，你有啥问题尽快问，我很忙。";
const InputSection = ({
  setmsgItems,
  conversations,
  setConversations,
  username,
  setLoading,
  sendMessage,
}) => {
  // const [conversations,setConversations] = useState([]);
  // const modelParams = useModelParams();
  // console.log(modelParams);
  // const authheader = useAuthorizedHeader();

  // const charPrompts = useCharPrompts();

  // const selectedChar = charPrompts.characters.find(
  //   (character) => character.name === charPrompts.selectedCharacterName
  // );

  // const systemMessage =
  //   selectedChar.primaryPrompt +
  //   (selectedChar.secondaryPrompt.trim()
  //     ? "###" + selectedChar.secondaryPrompt.trim()
  //     : "");

  // if (selectedChar.finalPrompt) {
  //   systemMessage = systemMessage + "###" + selectedChar.finalPrompt.trim();
  // }

  const formik = useFormik({
    initialValues: {
      prompt: "",
    },
    onSubmit: (values) => {
      if (values.length === 0) {
        return;
      }
      if (values.prompt.length === 0) {
        return;
      }
      const respid = generateUniqueId();
      setmsgItems((prev) => [
        ...prev,
        { id: respid, who: username, text: values.prompt },
      ]);

      formik.resetForm();
      setLoading(true);
      sendMessage({
        action: "sendmessage",
        payload: {
          // system: systemMessage,
          history: conversations,
          message: values.prompt,
        },
      });

      //save user input into conversations
      setConversations((prev) => [
        ...prev,
        { role: "user", text: values.prompt },
      ]);
    },
  });

  return (
    <Formik>
      <Form
        onSubmit={formik.handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            formik.handleSubmit();
            e.preventDefault();
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center",

            borderTop: 1,
            p: 1,
            bgcolor: grey[50],
            borderColor: grey[400],

            // gridTemplateColumns: "24px auto auto",
            position: "fixed",
            width: "100%",
            // height:32,
            bottom: 0,
          }}
        >
          <IconButton
            aria-label="refresh"
            edge="start"
            color="info"
            sx={{ ml: 0.25 }}
            onClick={() => {
              setConversations([]);
              setmsgItems([
                {
                  id: generateUniqueId(),
                  who: BOTNAME,
                  text: AI_GREETING,
                },
              ]);
              setLoading(false);
            }}
          >
            <RestartAltIcon size="medium" />
          </IconButton>
          <OutlinedInput
            sx={{ bgcolor: "white", flexGrow: 1, ml: 0.5, mr: 0.5 }}
            value={formik.values.prompt}
            onChange={(event) => {
              formik.setValues({ prompt: event.target.value });
            }}
            multiline
            placeholder="Please enter text"
          />
          <IconButton
            aria-label="send"
            edge="end"
            color="primary"
            type="submit"
            sx={{ mr: 2 }}
          >
            <SendIcon size="large" />
          </IconButton>
        </Box>
      </Form>
    </Formik>
  );
};

const ChatPage = () => {
  const [msgItems, setmsgItems] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [charPrompts, setCharPrompts] = useState(defaultCharPrompts);
  const [conversations, setConversations] = useState([]);

  const [local_stored_crediential] = useLocalStorage("chat-login-token", null);

  const username = local_stored_crediential.username;

  const didUnmount = useRef(false);
  const authtoken = useAuthToken();

  const onMessageCallback = ({ data }) => {
    //save conversations
    const resp = JSON.parse(data);
    if (resp.reason === "stop" && resp.message) {
      setConversations((prev) => [
        ...prev,
        { role: "assistant", text: resp.message },
      ]);
      setLoading(false);
      setmsgItems((prev) => [
        ...prev,
        {
          id: generateUniqueId(),
          who: BOTNAME,
          text: resp.message.trimStart(),
        },
      ]);
    } else {
      // remove last user messages
      setConversations((prev) => [...prev.slice(0, -1)]);
      setLoading(false);
      setmsgItems((prev) => [
        ...prev,
        {
          id: generateUniqueId(),
          who: BOTNAME,
          text: "发生错误，回复未返回，请重新输入你的信息",
        },
      ]);
    }
  };

  // setup websocket
  const { sendMessage, sendJsonMessage, getWebSocket, readyState } =
    useWebSocket(API_socket, {
      queryParams: authtoken,
      onOpen: () =>
        setmsgItems((prev) => [
          ...prev,
          {
            id: generateUniqueId(),
            who: BOTNAME,
            text: AI_GREETING,
          },
        ]),
      onMessage: onMessageCallback,

      // onClose: () => console.log('socket closed'),
      // onError: () => console.log('socket error'),
      onClose: () => {
        // setmsgItems((prev) => [
        //   ...prev,
        //   {
        //     id: generateUniqueId(),
        //     who: BOTNAME,
        //     text: "发生错误，连接已关闭",
        //   },
        // ]);
      },
      onError: () => {
        setmsgItems((prev) => [
          ...prev,
          {
            id: generateUniqueId(),
            who: BOTNAME,
            text: "发生错误，连接已关闭",
          },
        ]);
      },
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false;
      },
      reconnectAttempts: 100,
      reconnectInterval: 5000,
    });

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  return (
    // <charPromptsCtx.Provider value={charPrompts}>
    <Stack direction="column" spacing={2} sx={{ pb: 5 }}>
      {/* <TopNavHeader setCharPrompts={setCharPrompts} /> */}
      <ChatBox msgItems={msgItems} loading={loading} />
      <InputSection
        setmsgItems={setmsgItems}
        conversations={conversations}
        setConversations={setConversations}
        username={username}
        setLoading={setLoading}
        sendMessage={sendJsonMessage}
      />
    </Stack>
    // </charPromptsCtx.Provider>
  );
};

export default ChatPage;
